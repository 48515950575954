:root {
  --Black: #000000;
  --Neutrals90: #222325;
  --Neutrals100: #131416;
  --Neutrals80: #2b2d31;
  --Neutrals70: #35373b;
  --Neutrals60: #60646c;
  --Neutrals50: #93979f;
  --Neutrals40: #c9cbcf;
  --Neutrals30: #e9eaec;
  --Neutrals20: #f2f2f3;
  --Neutrals10: #f7f8f8;
  --White: #ffffff;
  --Primary10: #ffccff;
  --Primary20: #ff8fff;
  --Primary30: #ff70ff;
  --Primary40: #ff33ff;
  --Primary50: #e500e5;
  --Primary60: #cc00cc;
  --Primary70: #ad00ad;
  --Primary80: #8a008a;
  --Primary90: #660066;
  --Primary100: #4d004d;
  --Secondary10: #ccf8ff;
  --Secondary20: #adf4ff;
  --Secondary30: #85eeff;
  --Secondary40: #5ce9ff;
  --Secondary50: #36e0fb;
  --Secondary60: #05c3e0;
  --Secondary70: #008094;
  --Secondary80: #006170;
  --Secondary90: #004752;
  --Secondary100: #00353d;
  --Tertiary10: #e8fbbc;
  --Tertiary20: #e3faa8;
  --Tertiary30: #d9f88b;
  --Tertiary40: #cdf665;
  --Tertiary50: #c1f43e;
  --Tertiary60: #a0da0b;
  --Tertiary70: #7ca909;
  --Tertiary80: #587907;
  --Tertiary90: #476006;
  --Tertiary100: #354904;
  --Info10: #dbe5ff;
  --Info20: #b8cbff;
  --Info30: #85a4ff;
  --Info40: #5c85ff;
  --Info50: #3355ff;
  --Info60: #0b00ff;
  --Info70: #0800cc;
  --Info80: #070099;
  --Info90: #050066;
  --Info100: #04004d;
  --Success10: #d1ffcc;
  --Success20: #b6ffad;
  --Success30: #91ff85;
  --Success40: #6bff5c;
  --Success50: #22f20d;
  --Success60: #13cc00;
  --Success70: #16850a;
  --Success80: #0a6600;
  --Success90: #074d00;
  --Success100: #053300;
  --Error10: #ffe0db;
  --Error20: #ffb7ad;
  --Error30: #ff9385;
  --Error40: #ff6f5c;
  --Error50: #ff4b33;
  --Error60: #dc3018;
  --Error70: #c2200a;
  --Error80: #991200;
  --Error90: #7a0f00;
  --Error100: #520a00;
  --Warning10: #ffeecc;
  --Warning20: #ffe3ad;
  --Warning30: #ffd98f;
  --Warning40: #ffcf70;
  --Warning50: #ffc24c;
  --Warning60: #f3a816;
  --Warning70: #996600;
  --Warning80: #704a00;
  --Warning90: #523600;
  --Warning100: #332200;
  --OffWhite: #efeeeb;
}
